<template>
  <div class="container">
    <div class="page-title">
      <h1>Sign in!</h1>
    </div>
    <p>It's easy! It will only take a minute to connect with your preferred sign-in method.</p>
    <div class="buttons">
      <button
        class="btn twitch"
        :disabled="loading"
        @click="connectWithTwitch"
      >
        <i class="ic large ic-twitch"></i>
        Continue with Twitch
      </button>
      <button
        class="btn discord"
        :disabled="loading"
        @click="connectWithDiscord"
      >
        <i class="ic large ic-discord"></i>
        Continue with Discord
      </button>
    </div>
    <p class="container-footer">
      Your data is not shared with any third-party!<br>
      Read the <a href="/privacy-policy" target="_blank">Privacy Policy</a> to know more.
    </p>
  </div>
</template>

<script>
import { client } from '../api/api';

export default {
  name: 'LoginForm',
  props: {
    poll: String,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async connectWithTwitch() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      try {
        const { url } = await client.get('/twitch/connect', { pollId: this.poll });
        window.location.href = url;
      } catch (error) {
        console.warn(error.message);
        this.loading = false;
      }
    },
    async connectWithDiscord() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      try {
        const { url } = await client.get('/discord/connect', { pollId: this.poll });
        window.location.href = url;
      } catch (error) {
        console.warn(error.message);
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.page-title {
  margin-bottom: 32px;
}

.page-title h1 {
  font-size: 30px;
}

.page-title h1:after {
  left: 0;
  margin: auto;
}

.container {
  background: rgba(31, 29, 44, 0.69);
  border-radius: 16px;
  padding: 64px;
  max-width: 512px;
  text-align: center;
}

.container p {
  font-weight: 500;
}

.container .btn {
  height: 60px;
  border-radius: 30px;
}

.container .btn.twitch {
  margin-bottom: 12px;
}

.container .btn.discord .ic {
  width: 26px;
}

.container-footer {
  color: rgba(255, 255, 255, 0.69);
  border-top: 1px dashed rgba(255, 255, 255, 0.23);
  padding-top: 12px;
}

.container-footer a {
  font-weight: 600;
  color: #fff;
  text-decoration: underline;
}

.container .buttons {
  margin: 32px 0;
}

@media screen and (max-width: 580px) {
  .container {
    padding: 32px 16px;
  }
  .container .btn {
    padding: 0 16px;
    height: 52px;
  }
  .container .btn .ic {
    width: 24px;
    height: 24px;
  }
  .container .btn.discord .ic {
    width: 20px;
    height: 20px;
  }
}
</style>

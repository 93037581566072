<template>
  <div class="page poll" v-if="poll">
    <div class="wrapper">
      <div class="poll-title-container">
        <div class="poll-title">
          <h1>{{ poll.title }}</h1>
          <div class="poll-votes" v-if="user && user.id === poll.userId">You created this poll. You can’t vote!</div>
          <div class="poll-votes" v-else>You have <b>{{ votesLeft }}</b> vote{{ votesLeft > 1 ? 's' : '' }} left.</div>
          <div class="poll-total-votes" v-if="poll.settings && !poll.settings.hide_votes"><b><Number :value="totalVotes" /></b> vote{{ totalVotes > 1 ? 's' : '' }}</div>
        </div>
        <div class="poll-creator" v-if="poll.user">
          <span>by <b>{{ poll.user.name }}</b></span>
          <img :src="poll.user.avatar" :alt="poll.user.name" class="poll-user-avatar">
        </div>
      </div>
      <!-- <div class="poll-votes ended" v-if="!isRunning">This poll has ended.</div>
      <div class="poll-votes" v-else-if="(user ? user.id : authUser.uid) !== poll.userId">{{ poll.settings.games ? 'Click on a game to vote' : 'Cast your vote' }}! You have <b>{{ votesLeft }}</b> vote{{ votesLeft > 1 ? 's' : '' }} left.</div> -->
      <div class="poll-container">
        <div class="poll-search" v-if="!poll.settings.games && (user ? user.id : authUser.uid) !== poll.userId && canVote">
          <GameSearch :loading="loading" :excluded="poll.excludedGames" @select="sendVote" />
          <div class="poll-info"><i class="ic small ic-info"></i> Use the search field to find and vote for your preferred game or games. If a game is already listed, simply click on it to cast your vote.</div>
        </div>
        <PollItem :poll="poll" :recast-games="recastGames" :user-id="user ? user.id : fingerprint" :is-admin="user && user.admin" :user-votes="userVotes" layout="main" :clickable="(user ? user.id : authUser.uid) !== poll.userId && votesLeft > 0" @select="sendVote" />
      </div>
    </div>
    <div class="overlay" v-if="loginOpen" @click="loginOpen = false">
      <div @click.stop>
        <LoginForm :poll="poll.id" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  onSnapshot, doc, query, collection, where,
} from 'firebase/firestore';
import { mapState } from 'vuex';
import { db } from '../api';
import { getFingerprint } from '../utils/fingerprint';
import PollItem from '../components/PollItem.vue';
import GameSearch from '../components/GameSearch.vue';
import LoginForm from '../components/LoginForm.vue';
import Number from '../components/Number.vue';
import { client } from '../api/api';

export default {
  name: 'Poll',
  components: {
    PollItem,
    GameSearch,
    LoginForm,
    Number,
  },
  computed: {
    ...mapState(['user', 'authUser']),
    userVotes() {
      return this.myVote?.votes || [];
    },
    fingerprint() {
      return getFingerprint();
    },
    votesLeft() {
      const votes = this.poll?.settings?.votes_per_user || 1;
      if (this.myVote) {
        return Math.max(votes - this.myVote.votes.length, 0);
      }
      return votes;
    },
    isRunning() {
      if (!this.poll) {
        return false;
      }
      return !this.poll.ended_at || this.poll.ended_at.toDate() > new Date();
    },
    canVote() {
      if (!this.poll || !this.myVote) {
        return false;
      }
      const hasVoted = this.poll.settings?.votes_per_user ? this.myVote.votes.length >= this.poll.settings.votes_per_user : !!this.myVote.votes.length;
      return this.isRunning && !hasVoted;
    },
  },
  data() {
    return {
      loading: false,
      poll: null,
      pollUrl: null,
      games: [],
      gamesOpen: false,
      selectedGame: null,
      votes: [],
      recastGames: [],
      totalVotes: 0,
      myVote: null,
      linkCopied: false,
      loginOpen: false,
    };
  },
  methods: {
    async sendVote(game) {
      this.gamesOpen = false;
      if (!this.user) {
        this.loginOpen = true;
        return;
      }
      if (this.loading) {
        return;
      }
      this.recastGames.push(game.id);
      setTimeout(() => {
        this.recastGames = this.recastGames.filter((gameId) => gameId !== game.id);
      }, 5 * 60 * 1000);
      this.loading = true;
      try {
        const payload = {
          gameId: game.id,
        };
        const vote = await client.post(`/polls/${this.poll.id}/vote`, payload);
        this.myVote = vote;
      } catch (error) {
        console.log(error);
      }
      this.selectedGame = null;
      this.loading = false;
    },
    copyLink() {
      if (this.linkCopied) {
        return;
      }
      this.linkCopied = true;
      navigator.clipboard.writeText(this.pollUrl);
      setTimeout(() => {
        this.linkCopied = false;
      }, 500);
    },
    getGameColor(count) {
      const value = count / this.totalVotes;
      if (value <= 0.2) return '#9023D9';
      if (value <= 0.4) return '#FC0';
      if (value <= 0.6) return '#F2A81D';
      if (value <= 0.8) return '#D95204';
      return '#F36';
    },
    getPositionColor(index) {
      const value = index / this.totalVotes;
      const rgbStart = [242, 226, 5];
      const rgbEnd = [217, 82, 4];
      const red = rgbStart[0] + Math.round((rgbEnd[0] - rgbStart[0]) * value);
      const green = rgbStart[1] + Math.round((rgbEnd[1] - rgbStart[1]) * value);
      const blue = rgbStart[2] + Math.round((rgbEnd[2] - rgbStart[2]) * value);
      return `rgb(${red}, ${green}, ${blue})`;
    },
    getVoteState() {
      if (this.votesRef) {
        this.votesRef();
      }
      this.votesRef = onSnapshot(
        query(
          collection(db, 'votes'),
          where('userId', '==', this.user?.id || getFingerprint()),
          where('pollId', '==', this.$route.params.id),
        ),
        (docs) => {
          this.myVote = {
            votes: docs.docs.map((d) => d.get('gameId')),
          };
        },
      );
    },
  },
  mounted() {
    this.pollRef = onSnapshot(doc(db, `polls/${this.$route.params.id}`), (poll) => {
      if (!poll.exists()) {
        return this.$router.push('/');
      }
      const data = poll.data();
      this.poll = {
        ...data,
        settings: data.settings || {},
        created: data.created.toDate(),
        updated: data.updated.toDate(),
      };
      this.pollUrl = `${window.location.origin}/polls/${poll.id}`;
      let totalVotes = 0;
      this.votes = Object.entries(this.poll.votes).reduce((votes, [key, count]) => {
        const game = this.poll.games[key];
        if (game && count > 0) {
          votes.push({
            ...game,
            box_art_url: game.box_art_url.replace('{width}', '52').replace('{height}', '72'),
            count,
          });
          totalVotes += count;
        }
        return votes;
      }, []).sort((a, b) => b.count - a.count);
      this.totalVotes = totalVotes;
    });
    this.getVoteState();
  },
  beforeDestroy() {
    if (this.pollRef) {
      this.pollRef();
    }
    if (this.votesRef) {
      this.votesRef();
    }
  },
};
</script>

<style scoped>
.page {
  padding: 48px 0 0;
}

.poll-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  margin-bottom: 24px;
}

.poll-title {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.poll-title h1 {
  color: #FC0;
  font-size: 36px;
  text-transform: uppercase;
  margin-right: 24px;
}

.poll-title .poll-votes {
  font-weight: bold;
  font-size: 24px;
  padding: 12px;
  border-radius: 8px;
  background: rgba(31, 29, 44, 0.84);
  line-height: 1;
  margin-right: 16px;
}

.poll-title .poll-votes b {
  color: #FFEE00;
}

.poll-search {
  margin-bottom: 40px;
}

.poll-search .poll-info {
  font-style: italic;
  margin-top: 16px;
  padding: 0 18px;
}

.poll-creator {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.poll-creator img {
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-left: 12px;
}

@media screen and (max-width: 680px) {
  .poll-title-container {
    flex-direction: column;
    text-align: center;
  }
  .poll-title {
    justify-content: center;
  }
  .poll-title h1 {
    width: 100%;
    margin: 0 0 8px;
  }
}
</style>
